import React, {useEffect, useRef} from "react"
import Single from "../../single"
import info1 from "../../../../static/img/irstrat/articulo8.png";
import noticias14 from "../../../../static/img/blog/noticias-14-.jpg";

const Detail = (props) => {
    const isMountedComponent = useRef(true)
    useEffect(() => {
        if (isMountedComponent.current) {
            window.jquery_scripts()
        }
        return () => {
            isMountedComponent.current = false
        }
    })

    return (
        <Single location={props.location} title='Catalizadores positivos en un entorno incierto '>
            <div className="main_blog_items">
                <div className="main_blog_item">
                    <div className="main_blog_image img_hover">
                        <img alt={" "} src={noticias14}/>
                    </div>
                    <div className="main_blog_text">
                        <div className="post_info">
                            <a href="#" className="date">
                                15 Dic. 2020
                            </a>
                            <div className="blog_author_area">
                 <span>
                  Por : Miguel Bermejo
                </span>
                                <span>
                    Análisis del mercado
                </span>
                            </div>
                        </div>

                        <h2>Catalizadores positivos en un entorno incierto </h2>
                        <br/>
                    </div>
                </div>
                <div className="s_main_text">
                    <p>A pesar de la recesión económica que trajo consigo la pandemia de COVID-19, de acuerdo con Bank
                        of America (BofA), durante la semana que finalizó el 18 de noviembre, la asignación de recursos
                        por
                        parte de los inversionistas hacia las economías emergentes alcanzó su cuarto monto más grande
                        desde que se tienen registros, totalizando USD$3,500 mills. En este contexto, se percibe que los
                        inversionistas se encuentran comprando todo tipo de deuda, motivados por los mayores
                        rendimientos y menores riesgos que perciben entre las empresas de países como México. </p>

                    <p>Si bien, prevalece la preocupación por una posible interrupción de la recuperación económica ante
                        el aumento de infecciones de COVID-19, las perspectivas globales son influenciadas cada vez más
                        por
                        catalizadores positivos. En este respecto, el factor determinante ha sido la creación de
                        diversas
                        vacunas para combatir el virus SARS-CoV-2, cuyos buenos resultados alimentan la expectativa de
                        una
                        aplicación masiva hacia el 2T21, generando una ola de confianza sobre la pronta recuperación
                        económica mundial.</p>

                    <p>En el caso específico de Estados Unidos, se vislumbran otras dos señales positivas. La primera es
                        el
                        impulso económico proveniente del incremento en el ahorro de los hogares durante la cuarentena,
                        lo que en conjunto con las bajas tasas de interés, ha llevado a los analistas a prever grandes
                        posibilidades de que la demanda deprimida o <em>pent-up demand</em> (aquella que refiere a la
                        situación en
                        la que la demanda de un servicio o producto es inusualmente fuerte o que incrementa de manera
                        rápida)se materialice, incentivando un aumento adicional al pronóstico de 5.9% del PIB real en
                        2021. </p>

                    <p>La segunda señal positiva en EE. UU. es que se ha registrado un incremento en las ganancias de
                        las
                        compañías de pequeña capitalización (aquellas cuya capitalización de mercado oscila entre
                        USD$300
                        mills. y USD$2,000 mills.), lo que ofrece una prueba más de que la recuperación económica ha
                        cobrado impulso gradualmente. En este respecto, con el 91% de las acciones en el Índice Russell
                        2000<sup>1</sup> operando por delante de su media móvil de 50 días, el valor de los datos
                        históricos de 50 años
                        apunta a que habrá buenas probabilidades de retornos positivos en un futuro. </p>

                    <p>En el caso del mercado mexicano, ante la estrecha relación que existe con Estados Unidos, el
                        mayor
                        socio comercial del país, este se ha visto fuertemente influenciado por el avance económico y
                        las
                        posturas de la comunidad inversionista del país norteamericano. De acuerdo con las últimas
                        proyecciones del FMI en “Perspectivas de la Economía Mundial” publicadas en octubre de 2020, se
                        espera que la actividad económica de México se contraiga 9.0% para 2020, mientras que para el
                        2021, se elevó la expectativa de 3.3% a 3.5%, con un avance de 2.0% para los siguientes
                        años. </p>

                    <p>Asimismo, ante el triunfo electoral de Joe Biden como futuro presidente de EE. UU., se espera que
                        México presente una mejoría en el optimismo inversionista y un mayor apoyo en la globalización.
                        Por
                        otra parte, gracias al T-MEC se ha logrado reducir la incertidumbre relacionada con el comercio,
                        propiciando un mejor sentimiento en la comunidad inversora. A su vez, destaca que las
                        inversiones
                        en instrumentos mexicanos han presentado una ligera recuperación en comparación con otros países
                        emergentes. </p>

                    <p>Finalmente, las señales positivas más recientes fueron el crecimiento de 12.1% TsT del PIB
                        nacional
                        y las ratificaciones de las calificaciones crediticias de México de “BBB” en moneda extranjera y
                        “BBB+”
                        en moneda local por parte de Standard & Poor’s, así como de la calificación crediticia de “BBB-”
                        con
                        perspectiva estable asignada por la agencia calificadora Fitch Ratings el 11 de noviembre de
                        2020,
                        (señalando que el país no solo cuenta con un marco de política macroeconómica consistente, sino
                        que también tiene finanzas externas relativamente estables y robustas).</p>

                    <p>En resumen, si bien el panorama actual está marcado por una imperante incertidumbre, no hay que
                        perder de vista los catalizadores positivos que van surgiendo en el camino hacia la recuperación
                        económica. </p>

                    <p>
                        <small><sup>1</sup>Índice Russell 2000: índice bursátil que incluye las 2000 empresas con menor
                            capitalización del Índice Russel 3000.
                        </small>
                    </p>

                    <p>Referencias Bibliográficas
                        <ul>
                            <li>Shalett, L. (2020). Light at the End of the Tunnel. Noviembre 23, 2020. <a
                                href="https://www.morganstanley.com/ideas/light-end-of-tunnel" target="_blank">Morgan
                                Stanley</a>.
                            </li>
                            <li>Arnold, T. (2020). Rush for emerging market company bonds as investors look beyond
                                COVID-19. Noviembre 23, 2020. <a
                                    href="https://www.reuters.com/article/markets-emerging-corpbonds/rush-for-emerging-market-company-bonds-as-investors-look-beyond-covid-19-idUKL8N2HZ5NH"
                                    target="_blank">REUTERS</a>.
                            </li>
                            <li>Fitch Ratings. Fitch Affirms Mexico at 'BBB-'; Outlook Stable. Noviembre 11, 2020 <a
                                href="https://www.fitchratings.com/research/sovereigns/fitch-affirms-mexico-at-bbb-outlook-stable-11-11-2020"
                                target="_blank">Fitch Ratings</a>.
                            </li>
                            <li>Gonzáles, Z. E. (2020). La economía y las finanzas en México durante la pandemia.
                                Noviembre 6, 2020. <a
                                    href="https://www.bbva.mx/personas/noticias-inversiones/2020/economia-finanzas-mexico-covid.html"
                                    target="_blank">BBVA</a>.
                            </li>
                            <li>Fondo Monetario Internacional. Perspectivas de la Economía Mundial. Octubre 11, 2020. <a
                                href="https://www.imf.org/es/Publications/WEO/Issues/2020/09/30/world-economic-outlook-october-2020"
                                target="_blank">FMI</a>.
                            </li>
                        </ul>
                    </p>

                </div>
            </div>
        </Single>
    )
}

export default Detail
